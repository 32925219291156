import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser() {
	const navigate = useNavigate();

	const getToken = () => {
		const tokenString = sessionStorage.getItem('admincat');
		const userToken = JSON.parse(tokenString);
		return userToken;
	}

	const getUser = () => {
		const userString = sessionStorage.getItem('adminuser');
		const user_detail = JSON.parse(userString);
		return user_detail;
	}



	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());

	const saveToken = (user, token) => {
		sessionStorage.setItem('admincat', JSON.stringify(token));
		sessionStorage.setItem('adminuser', JSON.stringify(user));

		setToken(token);
		setUser(user);
		navigate('/poll/list');
	}

	const logout = () => {
		sessionStorage.clear();
		navigate('/login');
	}

	return {
		setToken: saveToken,
		token,
		user,
		getToken,
		logout
	}
}